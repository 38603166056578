<template>
  <div class="home">
    <h1>Live</h1>
    <p>
      <a href="/guest">Guest</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    this.$router.push("/guest")
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
